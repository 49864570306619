<!--
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->
<template>
  <div>
    <v-btn
      v-if="!isFirstCol"
      id="iiotWorkloadTableIcon"
      :class="[`${tableItem.type}-btn-color`, { disableIcon: tableItem.disabled }]"
      class="mb-1 mr-2"
      elevation="0"
      height="20"
      width="20"
      fab
    >
      <img v-if="tableItem.type === 'docker-compose'" :src="`/img/${tableItem.type}.svg`" height="20" width="20" />
      <v-icon v-else x-small>
        {{ tableItem.typeIcon }}
      </v-icon>
    </v-btn>
    <label>{{ isFirstCol ? tableItem.name : tableItem.type }}</label>
  </div>
</template>

<script>
export default {
  props: {
    isFirstCol: {
      type: Boolean,
      default: false,
    },
    tableItem: {
      type: Object,
      default: () => ({
        workload: {},
      }),
    },
  },
};
</script>

<style scoped>
.disableIcon {
  opacity: 0.3;
}
</style>
